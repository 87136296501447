/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import "../css/app.scss";

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';
import $ from "jquery";
// Need Global $ for leagcy code ? then uncomment it.
// create global $ and jQuery variables
global.$ = global.jQuery = $;

import "bootstrap"; //adds functions to jQuery

import "./components/cookieconsent.min.js";
